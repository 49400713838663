import { QUERY_PARAM_ERROR_KEY, i18n_PATH_ATTRIBUTE } from '../../constants';
import type { SpartaAuthLocaleChangedEvent, SpartaAuthLocaleChangedEventDetail } from '../../types';
import { type Locale, currentLocale, setLocale, translateHtml, t, type LocaleScopePath } from '../i18n';
import { Notifications } from './helpers';

/**
 * Handle the click event on the "show password" buttons
 */
const showPasswordButtons = Array.from(document.querySelectorAll<HTMLSpanElement>('[data-action="show-password"]'));
showPasswordButtons.forEach(button => {
  button.addEventListener('click', e => {
    e.preventDefault();
    e.stopImmediatePropagation();
    const currentButton = e.currentTarget as HTMLSpanElement;
    const inputEl = currentButton.parentElement?.children[0] as HTMLInputElement;
    const isText = inputEl.type === 'text';

    if (isText) {
      inputEl.type = 'password';
      currentButton.setAttribute(i18n_PATH_ATTRIBUTE, 'pages.common.showPassword');
      currentButton.innerText = t('pages.common.showPassword');
    } else {
      inputEl.type = 'text';
      currentButton.setAttribute(i18n_PATH_ATTRIBUTE, 'pages.common.hidePassword');
      currentButton.innerText = t('pages.common.hidePassword');
    }
  });
});

const languageSelect = document.querySelector<HTMLSelectElement>('select.language-select');
const languageFooterContainer = document.querySelector<HTMLDivElement>('footer .language-select-container');

/**
 * Handle locale change
 */
if (languageSelect) {
  addSelectLocaleChangeListener(languageSelect);
}

/**
 * Set the initial locale & handle i18n
 */
document.addEventListener('DOMContentLoaded', () => {
  const locale = currentLocale();
  document.documentElement.lang = locale;

  if (languageSelect && languageSelect?.value !== locale) {
    languageSelect.value = locale;
  }

  translateHtml();

  const queryError = new URL(window.location.href).searchParams.get(QUERY_PARAM_ERROR_KEY);
  const pageScope = document.body.getAttribute('data-scope');

  if (queryError && pageScope !== 'error') {
    Notifications.create(queryError as unknown as LocaleScopePath, 5000);
  }

  if (languageFooterContainer && languageSelect) {
    const languageSelectClone = languageSelect.cloneNode(true) as HTMLSelectElement;
    if (languageSelectClone && languageSelectClone?.value !== locale) {
      languageSelectClone.value = locale;
    }
    addSelectLocaleChangeListener(languageSelectClone);
    languageFooterContainer.appendChild(languageSelectClone);
  }
  document.documentElement.setAttribute('user-agent', navigator.userAgent);
  if (navigator.userAgent.indexOf('Win') !== -1) {
    document.documentElement.setAttribute('data-os', 'windows');
  }

  Array.from(document.querySelectorAll('select')).forEach(select => {
    select.setAttribute('data-selected', String(!!select.value));
    select.addEventListener('change', e => {
      const selectNode = e.target as HTMLSelectElement;
      selectNode.setAttribute('data-selected', String(!!selectNode.value));
    });
  });
});

document.addEventListener('SpartaAuthLocaleChanged', e => {
  const languageSelects = document.querySelectorAll<HTMLSelectElement>('select.language-select');

  const eventLocale = (e as SpartaAuthLocaleChangedEvent)?.detail?.locale;

  Array.from(languageSelects).forEach(select => {
    if (eventLocale) {
      // We want to update all language selects to the new locale
      select.value = eventLocale;
    }
  });

  /**
   * Update user culture in Cookiebot
   * there is no direct method to update the culture, so we need to renew the script src params
   */
  if (window.CookiebotDialog.visible) {
    if (typeof window.CookieConsent !== 'undefined') {
      const ucScriptElement: HTMLScriptElement =
        document.querySelector<HTMLScriptElement>(window.CookieConsent.scriptId) || window.CookieConsent.scriptElement;
      if (ucScriptElement && ucScriptElement?.src) {
        const ucScriptUrl = new URL(ucScriptElement.src);

        ucScriptUrl.searchParams.set('culture', currentLocale());
        ucScriptElement.src = ucScriptUrl.toString();

        // Refresh the cookiebot dialog
        if (!window.Cookiebot.consented && !window.Cookiebot.declined && !window.Cookiebot.isRenewal) {
          window.Cookiebot.show();
        }
        // Refresh the cookiebot renew dialog
        if (window.Cookiebot.isRenewal) {
          window.Cookiebot.renew();
        }
      }
    }
  }
});

/**
 * Custom event for locale change
 */
const SpartaAuthLocaleChangedEvent = new CustomEvent<SpartaAuthLocaleChangedEvent>('SpartaAuthLocaleChanged');

function addSelectLocaleChangeListener(select: HTMLSelectElement) {
  select.addEventListener('change', e => {
    const locale = (e.currentTarget as HTMLSelectElement)?.value as Locale;
    if (locale) {
      // Set the locale
      setLocale(locale);

      // Translate our document
      translateHtml();

      // Dispatch our custom locale change event for dependencies.
      document.dispatchEvent(
        new CustomEvent<SpartaAuthLocaleChangedEventDetail>('SpartaAuthLocaleChanged', { detail: { locale } }),
      );
    }
  });
}

const cookiesButton = document.querySelector<HTMLAnchorElement>('#cookiesSettingsButton');

cookiesButton?.addEventListener('click', e => {
  e.preventDefault();
  if (typeof window.Cookiebot !== 'undefined') {
    /**
     * Update user culture in Cookiebot
     * there is no direct method to update the culture, so we need to renew the script src params
     */
    if (typeof window.CookieConsent !== 'undefined') {
      const ucScriptElement: HTMLScriptElement =
        document.querySelector<HTMLScriptElement>(window.CookieConsent.scriptId) || window.CookieConsent.scriptElement;
      if (ucScriptElement && ucScriptElement?.src) {
        const ucScriptUrl = new URL(ucScriptElement.src);
        ucScriptUrl.searchParams.set('culture', currentLocale());
        ucScriptElement.src = ucScriptUrl.toString();
      }
    }
    window.Cookiebot?.renew?.();
  }
});

// window.addEventListener('CookiebotOnDialogInit', function (e) {
// });

/**
 * Mobile apps will send a custom user-agent string
 * that will be used to hide the cookie banner.
 */
window.addEventListener('CookiebotOnDialogDisplay', function (e) {
  // Hide the cookie banner for the mobile app
  if (navigator?.userAgent?.toLowerCase()?.includes('ac sparta praha')) {
    window.Cookiebot.hide();
  } else {
    // If the user language is different from the current locale, we need to update the user culture in Cookiebot
    if ((e.target as any)?.CookieConsentDialog?.userLanguage !== currentLocale()) {
      window.Cookiebot.hide?.();

      if (typeof window.CookieConsent !== 'undefined') {
        const ucScriptElement: HTMLScriptElement =
          document.querySelector<HTMLScriptElement>(window.CookieConsent.scriptId) ||
          window.CookieConsent.scriptElement;
        if (ucScriptElement && ucScriptElement?.src) {
          const ucScriptUrl = new URL(ucScriptElement.src);
          ucScriptUrl.searchParams.set('culture', currentLocale());
          ucScriptElement.src = ucScriptUrl.toString();
        }

        window.Cookiebot.show?.();
      }
    }
  }
});

declare global {
  interface Window {
    Cookiebot: {
      show: () => void;
      renew: () => void;
      hide: () => void;
      consented: boolean;
      declined: boolean;
      isRenewal: boolean;
      userCulture: string;
    };
    CookiebotDialog: {
      visible: boolean;
      userLanguage: string;
    };
    CookieConsent: {
      scriptId: string;
      scriptElement: HTMLScriptElement;
    };
  }
}
